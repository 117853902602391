import React from "react";
import { Container, Wrapper, H1, Line, Content, Wrap } from "./ComponentStyle";

const Component1 = () => {
  return (
    <Container>
      <Wrapper>
        <Wrap>
          <H1>Elite-Gain - Your ultimate Financial trading broker.</H1>
          <Line></Line>
          <Content>
            Elite-Gain analysts will use predictions to purchase profitable
            digital currency at the right time. We come up with customized
            portfolio services that offer a one percent risk aversion. This has
            allowed traders to see better return on their total index over time.
            It also protected them from potential risks with the market.
          </Content>
        </Wrap>
      </Wrapper>
    </Container>
  );
};
export default Component1;
